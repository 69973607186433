import React from 'react'

const AveragesModal = () => {
  return (
    <>
      <h1>Helllo from Averages Custom Component</h1>
    </>
  )
}
export default AveragesModal
